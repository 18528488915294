import React from "react";
import axiosClient from "../helpers/axiosClient";
import { Navigate } from 'react-router-dom';


/**
 * Logout
 * @returns {boolean}
 */
export function isLogin() {
    const bearerTokenHeader = localStorage.getItem('token');
    return !!bearerTokenHeader;
}

/**
 * storeToken
 * @param token
 * @returns {boolean}
 */
export function storeToken(token) {
    localStorage.setItem('token', token);
    return true;
}

/**
 * getToken
 * @param access_code
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getToken(access_code) {
    return axiosClient.post('/v1/jt/gen', JSON.stringify(access_code));
}


/**
 * logOut
 */
export function logOut() {
    removeTokens();
    <Navigate to="/" />;
}

/**
 * remove token
 */
export function removeTokens() {
    localStorage.removeItem('token');
    localStorage.removeItem('profileData');
}

export function reLogin() {
    removeTokens();
    const loginUrl = process.env.REACT_APP_LOGIN_URL || 'http://lp.jazzesl.com.pk';
    window.location.href = loginUrl;
}