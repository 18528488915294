import React, { useEffect } from 'react';
import HomeSlider from "../includes/HomeSlider";
import TournmentSlider from "../includes/TournmentSlider";
import GameTaskSlider from "../includes/GameTaskSlider";
import HFiveGameSlider from "../includes/HFiveGameSlider";
import YouTubeVideos from "../integrations/YouTubeVideos";

function HomePage() {
    useEffect(() => {
        document.title = 'Home | Jazz Esl';
    }, []);
    const shouldRenderYouTubeVideos = () => {
        const hostname = window.location.hostname;
        return hostname !== "ndnc.jazzesl.com.pk";
    };

    return (
        <>
            <HomeSlider />
            <GameTaskSlider />
            {/* <HFiveGameSlider /> */}
            <TournmentSlider />
            {shouldRenderYouTubeVideos() && <YouTubeVideos />}

        </>
    );
}

export default HomePage;