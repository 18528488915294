import React, {useEffect, useState} from 'react';
import '../../assets/css/modal.css';
import {v4 as uuidv4} from "uuid";
import {requestPostHTTP} from "../helpers/helper";

const Modal = ({ imageUrl, pushId, pushFireId, onClose }) => {
    const [loading, setLoading] = useState(true);

    function pushNotificationClick(){

        let pusherUniqueId = localStorage.getItem('pusherUniqueId');
        if (!pusherUniqueId) {
            pusherUniqueId = uuidv4();
            localStorage.setItem('pusherUniqueId', pusherUniqueId);
        }
            const userAgent = navigator.userAgent;

            console.log(pushId);
            try {
                requestPostHTTP('/v1/push_notification/clicked', {
                    pushId: pushId,
                    pushFireId: pushFireId,
                    userAgent: userAgent,
                    pusherUniqueId: pusherUniqueId
                }).then(response => {

                })
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }

    }
    return (
        <div className="modal-container pusher-notify-wrapper pusher-notification-modal" onClick={pushNotificationClick}>
            <div className="modal-content pusher-notification">
                <img src={imageUrl} alt="Modal" className="pusher-notification-img" />
                <button className="notify-close-btn" onClick={onClose}>
                    &times;
                </button>
            </div>
        </div>
    );
};

export default Modal;
