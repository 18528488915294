import React, { useEffect, useState } from 'react';
import '../../assets/css/tournament.css';
import { requestPostHTTP } from "../helpers/helper";
import { NavLink } from "react-router-dom";
import Loader from "../includes/Loader";

function ChallengesPage() {
    const [gameTasks, setGameTasks] = useState([]);
    const [notFoundTasks, setNotFoundTasks] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchReqData() {
            try {
                const getDailyGameTasks = await requestPostHTTP('/v1/challenges/games');
                if (getDailyGameTasks.data.length > 0) {
                    setGameTasks(getDailyGameTasks.data);
                } else {
                    setNotFoundTasks(true);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        fetchReqData();
    }, []);
    return (
        <section>
            <div className="cards-wrapper-tournament col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tournament-heading">
                    <h2 className="headingz mb-4">Daily Gaming Tasks</h2>
                    <p className="tournament-sub-heading">Play Weekly Tournaments and Earn Rewards</p>
                </div>

                <div className="row">
                    {loading ? (
                        <Loader />
                    ) : (
                        gameTasks.length > 0 && gameTasks.map((gameTask, index) => (

                            <div key={index} className="t-card-challenges">
                                <NavLink to={`/game-challenges/${gameTask.gameId}`}>
                                    <div className="cards-tournament-round">
                                        <img src={gameTask.thumbnail} alt="loading..." style={{ minHeight: "372px" }} />
                                        <div className="tournament-card-info">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h6 className="tournament-info-heading">{gameTask.game}</h6>
                                                    <p className="card-heading-tournament">Win Credits</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        ))
                    )}

                    {notFoundTasks && (
                        <div className="d-flex justify-content-center align-items-center no-data-found">
                            <p>No tournaments found</p>
                        </div>
                    )}

                </div>
            </div>
        </section>
    );
}

export default ChallengesPage;