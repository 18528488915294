import Header from '../components/includes/Header'
import {connect} from 'react-redux'
import {subscribedMobalOpen} from '../services/actions/action'

const mapStateToProps=state=>({
    subscribedModalRef: state.data
})
const mapDispatchToProps=dispatch=>({
    openSubscribedModal:data=>dispatch(subscribedMobalOpen(data))

})
export default connect(mapStateToProps,mapDispatchToProps)(Header)
