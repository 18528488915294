import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/tournament.css';
import { requestGetHTTP, requestPostHTTP } from "../helpers/helper";
import Loader from "../includes/Loader";
import { isLogin } from "../auth/AuthController";
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from "react-router-dom";
import rewardsImg from "../../assets/images/icons/rewards.png";
import { getLoginUrl } from '../helpers/urlHelper';

function TournamentPage() {
    const location = useLocation();
    const loginUrl = getLoginUrl(location);
    const [tournaments, setTournaments] = useState([]);
    const [notFoundTournament, setNotFoundTournament] = useState(false);
    const [tournamentCheckIn, setTournamentCheckIn] = useState([]);
    const [loading, setLoading] = useState(true);
    const modal = useSelector((state) => state.openSubscribedModal);
    const showSuccessModal = useRef(null);

    useEffect(() => {
        document.title = 'Tournaments | Jazz Esl'; // title
        async function fetchReqData() {
            try {

                const getTournaments = await requestGetHTTP('/v1/tournaments/all');
                if (getTournaments.data.length > 0) {
                    setTournaments(getTournaments.data);
                } else {
                    setNotFoundTournament(true);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        fetchReqData();

    }, []);

    /**
     *
     * @param tourn_id
     */
    function funTournamentCheckIn(event, tourn_id) {
        if (!isLogin()) {
            // modal.subscribedModalRef.current.click();
            return window.location.href = process.env.REACT_APP_LOGIN_URL
        }
        try {
            requestPostHTTP('/v1/tournament/entry/checkin', { id: tourn_id }).
                then(response => {
                    if (response && response.data.already_submited === true) { // already exist
                        setTournamentCheckIn('You have already Submitted.');
                        showSuccessModal.current.click();
                    } else if (response && response.data.length > 0) {
                        setTournamentCheckIn('Congratulations! You\'ve successfully entered in the tournament. Please wait for the upcoming message with further instructions on how to join. Thank you for your participation and patience.');
                        showSuccessModal.current.click();
                    }
                });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    /**
     *
     * @param event
     */
    const checkLogin = (event) => {
        if (!isLogin()) {
            event.preventDefault();
            // modal.subscribedModalRef.current.click();
            return window.location.href = loginUrl;
            // process.env.REACT_APP_LOGIN_URL
        }
    }

    return (
        <section>
            <div className="cards-wrapper-tournament tournament-wrapper col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tournament-heading">
                    <h2 className="headingz mb-4">Tournaments</h2>
                    <p className="tournament-sub-heading">Play Weekly Tournaments and Earn Rewards</p>
                </div>

                <div className="row">
                    {loading ? (
                        <Loader />
                    ) : (
                        tournaments.length > 0 && tournaments.map((tournament, index) => (
                            // <div key={index} className="col-12 col-sm-12 col-md-4 col-lg-4 tour-card-wrapper">
                            //     <NavLink onClick={(event) => checkLogin(event)} to={`/tournament-details/${tournament.tourn_id}`}>
                            //         <div className="cards-tournament-round">
                            //             <img src={tournament.thumbnail} alt="tournament-img" style={{ minHeight: "318px" }} />
                            //             <div className="tournament-card-info">
                            //                 <div className="row">
                            //                     <div className="col-8">
                            //                         <h6 className="tournament-info-heading">{tournament.tourn_name}</h6>
                            //                         <p className="card-heading-tournament"> Prizes</p>
                            //                         <p className="card-heading-tournament">1st: {tournament.prize} {tournament.tourn_type}</p>
                            //                         {tournament.second_prize && (
                            //                             <p className="card-heading-tournament">
                            //                                 2nd: {tournament.second_prize} {tournament.tourn_type}
                            //                             </p>
                            //                         )}
                            //                         {tournament.third_prize && (
                            //                             <p className="card-heading-tournament">3rd: {tournament.third_prize} {tournament.tourn_type}</p>
                            //                         )}

                            //                         <p className="tournament-page-total-participant">Participant: {tournament.total_checkin}</p>
                            //                     </div>
                            //                     <div className="col-4 comming-soon-tournament d-flex flex-column">
                            //                         <p>Starting at</p>
                            //                         <p>{tournament.time.split(" ")[0]}</p>
                            //                         <p>{tournament.time.split(" ")[1]}</p>
                            //                         <div style={{ width: "150px", right: "60px", position: "relative", alignSelf: "flex-start", marginTop: "auto", marginBottom: "25px" }}>
                            //                             {tournament.isEntryFree === 0 || !tournament.isEntryFree ?
                            //                                 <p style={{ fontSize: "13px", color: "white", lineHeight: "20px", textAlign: "center" }}>Enter from FREE!</p>
                            //                                 : <p style={{ fontSize: "14px", color: "white", lineHeight: "20px", textAlign: "center" }}> <span style={{ fontSize: "13px" }}>Tournament</span> <br /> Entry Fee-{tournament.entryFee} Credits</p>}

                            //                         </div>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </NavLink>
                            // </div>
                            <div key={index} className="t-card" >
                                <div className="cards-round w275i" style={{ background: "transparent" }}>
                                    <NavLink onClick={(event) => checkLogin(event)} to={`/tournament-details/${tournament.tourn_id}`}>
                                        <img className="br-15" src={tournament.thumbnail} alt="loading..." style={{ width: "100%", height: "372px", background: "rgb(31, 34, 42)" }} />
                                        <div className="col-8 cards-info float-start">
                                            <h3 className="cards-label text-white fs-18 mtc-15">
                                                {tournament.tourn_name}
                                            </h3>
                                            <p className="card-heading-tournament fs-15 mtc-5 lineHeight25i"> Prizes</p>
                                            <p className="card-heading-tournament fs-15 mtc-5 lineHeight25i">1st: {tournament.prize} {tournament.tourn_type}</p>
                                            {tournament.second_prize && (
                                                <p className="card-heading-tournament fs-15 mtc-5 lineHeight25i">
                                                    2nd: {tournament.second_prize} {tournament.tourn_type}
                                                </p>
                                            )}
                                            {tournament.third_prize && (
                                                <p className="card-heading-tournament fs-15 mtc-5 lineHeight25i">3rd: {tournament.third_prize} {tournament.tourn_type}</p>
                                            )}

                                            <p className="mt-3 m-0 pb-4 tournament-total-participant">Participant: {tournament.total_checkin}</p>
                                        </div>
                                        <div className="col-4 comming-soon-slider-tournament float-end d-flex flex-column mt-15">
                                            <p>Starting at</p>
                                            <p style={{ minWidth: "73px" }}>{tournament.time.split(" ")[0]}</p>
                                            <p>{tournament.time.split(" ")[1]}</p>
                                            <div style={{ width: "150px", right: "40px", position: "relative", alignSelf: "flex-start", marginTop: "auto", marginBottom: "25px" }}>
                                                {tournament.isEntryFree === 0 || !tournament.isEntryFree ?
                                                    <p style={{ fontSize: "12px", color: "white", lineHeight: "20px", textAlign: "center" }}>Enter from FREE!</p>
                                                    : <p style={{ fontSize: "12px", color: "white", lineHeight: "20px", textAlign: "center" }}> <span style={{ fontSize: "13px" }}>Tournament</span> <br /> Entry Fee-{tournament.entryFee} Credits</p>}

                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        ))
                    )}
                    {notFoundTournament && (
                        <div className="d-flex justify-content-center align-items-center no-data-found">
                            <p>No tournaments found</p>
                        </div>
                    )}
                </div>
            </div>
            {/*Model*/}
            <div className="modal fade subscribe-modal" id="showSuccess" tabIndex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog subscribe-modal-dialog">
                    <div className="modal-content subscribe-modal-content">
                        <div className="modal-body subscribe-modal-body">
                            <div className="subscribe-modal-body-back-img notification-back-img">
                                <img className="rewards-modal-icon" src={rewardsImg} alt="rewards-success" />
                            </div>
                            <h6 className="subscribe-info-heading-modal">Congratulations</h6>
                            <p className="subscribe-heading-modal">{tournamentCheckIn}</p>
                        </div>
                    </div>
                </div>
            </div>
            <button style={{ display: 'none' }} ref={showSuccessModal} data-bs-toggle="modal" data-bs-target="#showSuccess">modal box</button>
        </section>
    );
}

export default TournamentPage;